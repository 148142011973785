import React, { useState } from "react";
import "./music.css";
import Slider from "react-slick";
import Heading from "../../../common/heading/Heading";
import { popular } from "../../../../dummyData";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

const Music = () => {
  const [likes, setLikes] = useState(
    popular.map((item) => ({ id: item.id, count: 0 }))
  );

  const handleLike = (id) => {
    setLikes((prevLikes) =>
      prevLikes.map((like) =>
        like.id === id ? { ...like, count: like.count + 1 } : like
      )
    );
  };

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return ` Pending for ${interval} months `;

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `Pending for ${interval} days`;

    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `Pending for ${interval} hours `;

    interval = Math.floor(seconds / 60);
    if (interval > 1) return `Pending for ${interval} minutes `;

    return `just now`;
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
  };

  return (
    <>
      <section className="music">
        <Heading title="Prediction Curve" />
        <div className="content">
          <Slider {...settings}>
            {popular
            .map((val) => {
              const like = likes.find((like) => like.id === val.id);
              const shareUrl = `${window.location.origin}/post/${val.title}`;
            
                return (
                  <div className="items" key={val.id}>
                    <div className="box shadow flexSB">
                      <div className="images">
                        <div className="img">
                          <img src={val.cover} alt={val.title} />
                        </div>
                        <div className="category category1">
                          <span>{val.category}</span>
                        </div>
                      </div>
                      <div className="text">
                        <h1 className="title">{val.title.slice(0, 150)}...</h1>
                        <div className="date">
                          <i className="fas fa-calendar-days"></i>
                          <label>{timeAgo(val.date)}</label>
                        </div>
                        <p className="desc">{val.desc.slice(0, 250)}...</p>
                        <div className="comment">
                          <div className="share-buttons">
                            <FacebookShareButton url={shareUrl} quote={val.title}>
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={val.title}>
                              <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <LinkedinShareButton url={shareUrl} title={val.title}>
                              <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                            <EmailShareButton url={shareUrl} subject={val.title}>
                              <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                          </div>
                          <i className="fas fa-comments"></i>
                          <label>{val.comments}</label>
                          <div className="like" onClick={() => handleLike(val.id)}>
                            <i className="fas fa-thumbs-up"></i>
                            <label>{like.count} Likes</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Music;

import React, { useState } from "react";
import Slider from "react-slick";
import { ppost } from "../../../../dummyData";
import Heading from "../../../common/heading/Heading";
import "./ppost.css";


const Ppost = () => {
  // Initialize likes state for each post
  const [likes, setLikes] = useState(
    ppost.map((item) => ({ id: item.id, count: 0 }))
  );

  // Function to handle like increment
  const handleLike = (id) => {
    setLikes((prevLikes) =>
      prevLikes.map((like) =>
        like.id === id ? { ...like, count: like.count + 1 } : like
      )
    );
  };

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);
  
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return ` Pending for ${interval} months `;
  
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `Pending for ${interval} days`;
  
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `Pending for ${interval} hours `;
  
    interval = Math.floor(seconds / 60);
    if (interval > 1) return `Pending for ${interval} minutes `;
  
    return `Completed`;
  };
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <>
      <section className="popularPost">
        <Heading title="Popular Posts" />
        <div className="content">
          <Slider {...settings}>
            {ppost.map((val) => {
              // Find the like count for the current post
              const like = likes.find((like) => like.id === val.id);

              return (
                <div className="items" key={val.id}>
                  <div className="box shadow">
                    <div className="images">
                      <div className="img">
                        <img src={val.cover} className={val.className} alt="" />
                      </div>
                      <div className="category category1">
                        <span>{val.category}</span>
                      </div>
                    </div>
                    <div className="text">
                      <h1 className="title">{val.title.slice(0, 150)}...</h1>
                      <div className="date">
                        <i className="fas fa-calendar-days"></i>
                        <label>{timeAgo(val.date)}</label>
                      </div>
                      <div className="comment">
                        <i className="fas fa-share"></i>
                        <label>Share / </label>
                        <i className="fas fa-comments"></i>
                        <label>{val.comments}</label>
                        <div
                          className="like"
                          onClick={() => handleLike(val.id)}
                        >
                          <i className="fas fa-thumbs-up"></i>
                          <label>{like.count} Likes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Ppost;

import React, { useState } from "react";
import { hero } from "../../../dummyData";
import "./hero.css";
import Card from "./Card";

const Hero = () => {
  const [items, setItems] = useState(hero); // Fixed typo in setItems

  return (
    <section className='hero'>
      <div className='container'>
        {items.map((item) => {
          return (
            <Card key={item.id} item={item} /> // Moved key prop to Card component
          );
        })}
      </div>
    </section>
  );
}

export default Hero;

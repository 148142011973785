import React from "react";
import { Link } from "react-router-dom";
import './Card.css'; 

const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return ` Pending for ${interval} months `;

  interval = Math.floor(seconds / 86400);
  if (interval > 1) return `Pending for ${interval} days`;

  interval = Math.floor(seconds / 3600);
  if (interval > 1) return `Pending for ${interval} hours `;

  interval = Math.floor(seconds / 60);
  if (interval > 1) return `Pending for ${interval} minutes `;

  return `Completed`;
};

const Card = ({ item: { id, cover, category, title, authorName, time } }) => {
  return (
    <>
      <div className='box'>
        <div className='img'>
          <img src={cover} alt={title} />
        </div>
        <div className='text'>
          <span className='category'>{category}</span>
          <Link to={`/SinglePage/${id}`}>
            <h1 className='titleBg'>{title}</h1>
          </Link>
          <div className='author flex'>
            {/* <span>by {authorName}</span> */}
          
            <span className='time-ago'>{timeAgo(time)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;

import React from "react"

const Culture = () => {
  return (
    <>
      <section className='culture'>
        <div className='container paddingTB'>
          <h1>Policies here</h1>
        </div>
      </section>
    </>
  )
}

export default Culture

import React, { useState } from "react";
import "./Popular.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { popular } from "../../../../dummyData";
import Heading from "../../../common/heading/Heading";
import ModalShare from "../Modalshare"; // Import the modal component

const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return `Pending for ${interval} months`;

  interval = Math.floor(seconds / 86400);
  if (interval > 1) return `Pending for ${interval} days`;

  interval = Math.floor(seconds / 3600);
  if (interval > 1) return `Pending for ${interval} hours`;

  interval = Math.floor(seconds / 60);
  if (interval > 1) return `Pending for ${interval} minutes`;

  return `Completed`;
};

const Popular = () => {
  const [likes, setLikes] = useState(
    popular.map((item) => ({ id: item.id, count: 0 }))
  );
  const [shareModal, setShareModal] = useState(null); // Track the post ID for the active share modal
  const [dropdownOpen, setDropdownOpen] = useState(null); // Track which dropdown is open

  const handleLike = (id) => {
    setLikes((prevLikes) => 
      prevLikes.map((like) =>
        like.id === id ? { ...like, count: like.count + 1 } : like
      )
    );
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id); // Toggle dropdown visibility
  };

  const copyLink = (url) => {
    navigator.clipboard.writeText(url); // Copy the link to clipboard
    alert("Link copied to clipboard!"); // Optional alert
  };

  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 2,
    speed: 500,
    rows: 4,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 4,
        },
      },
    ],
  };

  return (
    <>
      <section className="popular">
        <Heading title="Popular" />
        <div className="content">
          <Slider {...settings}>
            {popular.map((val) => {
              const like = likes.find((like) => like.id === val.id);
              const shareUrl = `${window.location.origin}/post/${val.title}`;

              return (
                <div className="items" key={val.id}>
                  <div className="box shadow">
                    <div className="images row">
                      <div className="img">
                        <img src={val.cover} alt={val.title} />
                      </div>
                      <div className="category category1">
                        <span>{val.category}</span>
                      </div>
                    </div>
                    <div className="text row">
                      <h1 className="title">{val.title.slice(0, 150)}...</h1>
                      <div className="date">
                        <i className="fas fa-calendar-days"></i>
                        <label>{timeAgo(val.date)}</label>
                      </div>
                      <div className="comment">
                        {/* Share icon to toggle dropdown */}
                        <i className="fas fa-share" onClick={() => toggleDropdown(val.id)}></i>
                        
                        {/* Dropdown menu */}
                        {dropdownOpen === val.id && (
                          <div className="dropdown">
                            <button onClick={() => setShareModal(val.id)}>
                              Share post via...
                            </button>
                            <button onClick={() => copyLink(shareUrl)}>
                              Copy link
                            </button>
                          </div>
                        )}

                        <i className="fas fa-comments"></i>
                        <label>{val.comments}</label>
                        <div className="like" onClick={() => handleLike(val.id)}>
                          <i className="fas fa-thumbs-up"></i>
                          <label>{like.count} Likes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        {/* Modal to display sharing options */}
        {shareModal !== null && (
          <ModalShare
            show={shareModal !== null}
            onClose={() => setShareModal(null)}
            shareUrl={`${window.location.origin}/post/${popular.find(item => item.id === shareModal).title}`}
            title={popular.find(item => item.id === shareModal).title}
          />
        )}
      </section>
    </>
  );
};

export default Popular;

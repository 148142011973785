import React from "react";
import { discover } from "../../../dummyData";
import Heading from "../../common/heading/Heading";
import "./style.css";

const Discover = () => {
  return (
    <>
      <section className='discover'>
        <div className='container'>
          <Heading title='Performance Tracker' />
          <div className='content'>
            {discover.map((val) => {
              return (
                <div className='box' key={val.id}> {/* Add a unique key here */}
                  <div className='img'>
                    <img src={val.cover} alt={val.title} className={val.className} /> {/* It's good practice to use a meaningful alt text */}
                  </div>
                  <h1 className='title'>{val.title}</h1>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Discover;

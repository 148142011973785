import React from "react"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container'>
          <div className='box logo'>
            <img src='../images/tech-logo-footer.png' alt='' />
             PerformanceTracker
            <p>Empowering Accountability and Transparency. Track the heartbeat of political performance with our Performance Tracker app. Stay informed, hold leaders accountable, and drive positive change. Your tool for a better political landscape</p>
            <i className='fa fa-envelope'></i>
            <span> PerfromanceTracker@gmail.com </span> <br />
            <i className='fa fa-headphones'></i>
            <span> +233 00 000 000</span>
          </div>
          <div className='box'>
            <h3>Statistics</h3>
            <div className='item'>
              <img src='../images/stats.jpeg' alt='' />
              <p>related contents</p>
            </div>
            <div className='item'>
              <img src='../images/stat.png' alt='' />
              <p>related contents</p>
            </div>
          </div>
          <div className='box'>
            <h3>Analytics Outline</h3>
            <div className='item'>
              <img src='../images/ana.webp' alt='' />
              <p>Related content</p>
            </div>
            <div className='item'>
              <img src='../images/ana.png' alt='' />
              <p>related content</p>
            </div>
          </div>
          <div className='box'>
            <h3>LABELS</h3>
            {/*<i className='fa fa-chevron-right'></i>*/}
            <ul>
              <li>
                <span>Flagbearers</span> <label>(5)</label>
              </li>
              <li>
                <span>MPs</span> <label>(6)</label>
              </li>
              <li>
                <span>DCE's</span> <label>(7)</label>
              </li>
              <li>
                <span>Local Governments</span> <label>(9)</label>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal  '>
        <div className='container flexSB'>
          <p>© all rights reserved</p>
          <p>
            Take a dive into the future <i className='fa fa-heart'></i> 
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer

import React from "react";
import "./side.css";
import Slider from "react-slick";
import Heading from "../../../common/heading/Heading";
import { gallery } from "../../../../dummyData";
import Tpost from "../Tpost/Tpost";
import SocialMedia from "../social/SocialMedia";

const Side = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const categories = [
    "Governance",
    "Infrastructure",
    "Healthcare", 
    "Sports",
    "Economy",
    "Education",
    "Agricuture",
    "Ministry",
  ];

  return (
    <>
      <Heading title='Stay Connected' />
      <SocialMedia />

      <Heading title='Subscribe' />

      <section className='subscribe'>
        <h1 className='title'>Subscribe to our New Stories</h1>
        <form action=''>
          <input type='email' placeholder='Email Address...' />
          <button>
            <i className='fa fa-paper-plane'></i> SUBMIT
          </button>
        </form>
      </section>

      <section className='banner'>
        <img src='./images/sidebar-banner-new.jpg' alt='Sidebar Banner' />
      </section>

      <Tpost />

      <section className='categories'> {/* Fixed typo from 'catgorys' to 'categories' */}
        <Heading title='Categories' />
        {categories.map((val) => {
          return (
            <div className='category category1' key={val}> {/* Use val as key for uniqueness */}
              <span>{val}</span>
            </div>
          );
        })}
      </section>

      <section className='gallery'>
        <Heading title='Gallery' />
        <Slider {...settings}>
          {gallery.map((val) => {
            return (
              <div className='img' key={val.id}>
                <img src={val.cover} alt={val.title} />
              </div>
            );
          })}
        </Slider>
      </section>
    </>
  );
};

export default Side;

import React from "react";
import { tpost } from "../../../../dummyData";
import Heading from "../../../common/heading/Heading";
import "./tpost.css";
import * as timeago from "timeago.js";

const Tpost = () => {
  return (
    <>
      <section className='tpost'>
        <Heading title='Other posts' />
        {tpost.map((val) => {
          return (
            <div className='box flexSB' key={val.id}> {/* Add a unique key here */}
              <div className='img'>
                <img src={val.cover} alt={val.title} /> {/* Use a meaningful alt text */}
              </div>
              <div className='text'>
                <h1 className='title'>{val.title.slice(0, 35)}...</h1>
                <span>{timeago.format(val.date)}</span>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default Tpost;
